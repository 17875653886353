import React, { useState, useEffect, useRef, useContext } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';

function ScreenSaverControl() {
    const { isSupported, released, request, release } = useWakeLock({});
    const [screenSaverStatus, setScreenSaverStatus] = useState(false);
    const [indicatorColour, setIndicatorColour] = useState('InactiveColour')
    if (!isSupported) {
        console.log('not Supported')
    }
    useEffect(() => {
        if (screenSaverStatus === true) {
            request();
            setIndicatorColour('OnColour')
        }
        else {
            release();
            setIndicatorColour('OffColour')
        }
    }, [screenSaverStatus]);
    
    useEffect(() => {
   // if it is released (ie from changing windows), then turn off the status indicator
        if (released) { setScreenSaverStatus(false) }
    }, [released])

    function cycleScreenSaverMode() {
        setScreenSaverStatus(prevState => !prevState);
    }

    return (
        <div className={indicatorColour + ' indicatorDot'} id='screenSaverToggleControl' onClick={cycleScreenSaverMode}>
            &#9679;</div>);
};

export default ScreenSaverControl;