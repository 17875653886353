import React, { useContext ,useEffect} from 'react';
import { AppContext } from './context/AppContext';
import ReturnHome from './ReturnHome';

function EditEventMain(props) {
    const { FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
        period, setPeriod, counters, setCounters } = useContext(AppContext);
    
        useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [])

    function deleteEvent(indexToDelete) {
        console.log("Delete event" + indexToDelete)
        console.log("Delete this event:" + events[indexToDelete])
        console.log("All Events:" + events)
        // delete the event type
        const eventType = events[indexToDelete].eventType

        decrementCounter(eventType);
        console.log("deleting event" + eventType)
        setEvents(prevEvents => prevEvents.filter((_, index) => index !== indexToDelete));
    }

    const decrementCounter = (property) => {
        setCounters({
            ...counters,
            [property]: counters[property] - 1,
        });
    };

    return (
        <div>
            <h1>Edit Events Page</h1>
            {/* <div id='editEventWindow' className='fullWindowToggle'> */}
            <div>
                <h1>Event Editing Window</h1>
                {/* <button id='saveSettingsButton' onClick={toggleVisibility} >Close Editing</button> */}
                <div className="scrollable-div eventWindow editEventWindow" id="scrollable-div" >
                    <ul>
                        {events.map((event, index) => (
                            <li className='event' key={index} onClick={() => deleteEvent(index)}>
                                {index}:{event.period},{event.time},{event.eventType}
                            </li>
                        ))}
                    </ul></div>
                There is some text here<br />
            </div>
            <ReturnHome />
        </div>
    );
}

export default EditEventMain;