// About.js
import React from 'react';
import ReturnHome from './ReturnHome';

function About() {
  return <div><h1>About Page</h1><p> This is some content</p>
    For support/comments/feedback<br />
    <a href="mailto:support@hockeyscoretracker.com">support@hockeyscoretracker.com</a><br /><br/>

    This is a basic proof of concept app. I'm developing it for my own use but I'd be glad to hear if anyone is finding it useful.  <br /> <br/>

    Feel free to send me an email I <b><u>REALLY</u></b> want feedback and comments.<br/><br/>

    Checkout my other projects
    <a href="http://www.bossthree.com">http://www.bossthree.com</a>
    <br />
<ReturnHome/>
  </div>;
}

export default About;