// import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
// import { Link } from 'react-router-dom';
import { AppContext } from './context/AppContext';

import React, { useContext, useState, useEffect, useRef } from 'react';
import PenaltyDisplay from './PenaltyDisplay';
import './App.css';
import Main from "./Main";
import ScreenSaverControl from './ScreenSaverControl';

import Settings from './Settings';
import EditEventWindow from './EditEventWindow';
import SettingsMain from './SettingsMain';
import EditEventMain from './EditEventMain';
import EditTimeMain from './EditTimeMain';

function App() {

  const { FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
    period, setPeriod, counters, setCounters } = useContext(AppContext);

  // Timer old method
  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          const newTimer = Math.max(prevTimer - 0.1, 0)
          if (newTimer === 0) {
            setRunning(false);
          }
          return (newTimer);
        });
      }, 100);
    }
    return () => clearInterval(interval);
  }, [running]);


  //Hamburger MenuSTuff
  const [hideSettingsWindow, setSettingsWindowVisibility] = useState(false);

  let hideHamburger = () => {
    document.querySelector("#settingsWindow").style.display = "none"
    setSettingsWindowVisibility(false);
  }

  const toggleVisibility = () => {
    if (hideSettingsWindow) {
      document.querySelector("#settingsWindow").style.display = "none"
      setSettingsWindowVisibility(false);
    } else {
      document.querySelector("#settingsWindow").style.display = "block"
      setSettingsWindowVisibility(true);
    }
  };

  console.log('Running app');

  return (
    <div>
                {/* <ScreenSaverControl FLAGS={FLAGS} /> */}

      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <div>
          <div id='settingsButton' className='hamburger'>
            <table className='hamburger'> <tr>
                <td className='hamburger'><ScreenSaverControl /></td>
                <td onClick={toggleVisibility} className='hamburger'>
                  <div className="hamburgerRotated"> |||</div>
                </td></tr></table>

            {/* <button id='settingsButton2' >Settings</button> */}
            {/* <div className='wakeLockIndicator'>x{isLocked?'locked':'notlocked'}</div> */}
          </div>
          <div id='settingsWindow' className='fullWindowToggle' >
            <h1>Navigation</h1>
            <nav>
              <ul>
                <li> <Link to="/" onClick={toggleVisibility}> Home</Link> </li>
                <li> <Link to="/editevents" onClick={toggleVisibility}>Edit Events</Link> </li>
                <li> <Link to="/edittime" onClick={toggleVisibility}>Edit Time</Link> </li>
                <li> <Link to="/settings" onClick={toggleVisibility}>Settings</Link> </li>
                <li> <Link to="/about" onClick={toggleVisibility}>About</Link> </li>
              </ul>
            </nav>
          </div>
          <Routes>
            <Route path="/" element={<Main hideHamburger={hideHamburger} />} />
            <Route path="/settings" element={<SettingsMain hideHamburger={hideHamburger} />} />
            <Route path="/edittime" element={<EditTimeMain hideHamburger={hideHamburger} />} />
            <Route path="/editevents" element={<EditEventMain hideHamburger={hideHamburger} />} />
            <Route path="/about" element={<About hideHamburger={hideHamburger} />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App

