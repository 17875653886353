import React, { useContext, useEffect } from 'react';
import { AppContext } from './context/AppContext';
import ReturnHome from './ReturnHome';
import { useWakeLock } from 'react-screen-wake-lock';


function SettingsMain(props) {
    const { FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
        period, setPeriod, counters, setCounters } = useContext(AppContext);
    const GlobalFlags = FLAGS;

    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [])

    return (
        <div>
            <h1>Settings Page</h1>
            These flags are for testing/development purposes, not for general use <br />
            <table><tbody>
                {Object.keys(FLAGS).map(key => (
                    <tr key={key} onClick={
                        () => setFlags(prev => ({
                            ...prev,
                            [key]: !FLAGS[key]
                        }))}>
                        <td>{key}</td><td>{FLAGS[key] ? 'True' : 'False'}</td></tr>
                ))}
            </tbody></table>
            <ReturnHome />
        </div>
    );
}

export default SettingsMain;