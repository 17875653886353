import ReturnHome from './ReturnHome';
import React from 'react';
import './ClockTime.css'
import DigitalClock from './DigitalClock';
import { AppContext } from './context/AppContext';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';


function EditTimeMain(props) {
    const { FLAGS, setFlags, timer, setTimer, running, setRunning, events, setEvents,
        period, setPeriod, counters, setCounters } = useContext(AppContext);
    const navigate = useNavigate();
    
    useEffect(() => {
        // every time I go to the main page, close the popup
        props.hideHamburger();
    }, [])

    const startTimerAndReturn = () => { 
        setRunning(true);
        navigate('/');
     };

    function stopTimer ()
    {
        setRunning(false);
    }

    function handleDivTimeChange(e) {
        stopTimer();
        setTimer(e);
    };

    function addTime(e) {
        setTimer(prev => (prev + Number(e.target.value)));
    };
    function subtractTime(e) {
        setTimer(prev => Math.max(prev - Number(e.target.value), 0));
    }
    const handlePeriodChange = (e) => {
        setPeriod(e);
    };

    return (
            <div id="setTimeWindow" >
                {/* className='fullWindowToggle' > */}
                <h1>Set Time Window</h1>
                <DigitalClock fullShort={fullShort} timer={timer} period={period} />
                <button className='timeAdjust' onClick={addTime} value='60' >&lt;&lt;</button>
                <button className='timeAdjust' onClick={addTime} value='10' >&lt;</button>
                <button className='timeAdjust' onClick={addTime} value='1' >+</button>
                &nbsp;&nbsp;
                <button className='timeAdjust' onClick={subtractTime} value='1' >-</button>
                <button className='timeAdjust' onClick={subtractTime} value='10' >&gt;</button>
                <button className='timeAdjust' onClick={subtractTime} value='60' >&gt;&gt;</button>
                <br />
                <table><tbody><tr>
                    <td>
                        <div className='setTimeDiv' onClick={() => handleDivTimeChange(600)} >10 min</div><br />
                        <div className='setTimeDiv' onClick={() => handleDivTimeChange(720)} >12 min</div><br />
                        <div className='setTimeDiv' onClick={() => handleDivTimeChange(900)} >15 min</div><br />
                        <div className='setTimeDiv' onClick={() => handleDivTimeChange(1200)} >20 min</div><br />
                        <div className='setTimeDiv' onClick={() => handleDivTimeChange(0)} >SO-0min</div><br />
                    </td>
                    <td>
                        <div className='setTimeDiv' onClick={() => handlePeriodChange("1st")}>1st</div><br />
                        <div className='setTimeDiv' onClick={() => handlePeriodChange("2nd")} >2nd</div><br />
                        <div className='setTimeDiv' onClick={() => handlePeriodChange("3rd")} >3rd</div><br />
                        <div className='setTimeDiv' onClick={() => handlePeriodChange("OT")} >OT</div><br />
                        <div className='setTimeDiv' onClick={() => handlePeriodChange("SO")} >SO</div><br />
                    </td>
                </tr></tbody></table><br />
                <button className='start' disabled={running} onClick={startTimerAndReturn}>Start</button>
                <button className='stop' disabled={!running} onClick={stopTimer}>Stop</button>
                <br />
            <ReturnHome />
        </div>
    );
}



function fullShort(timer) {
    // if (timer < 60) {
    //     return formatSeconds(timer, 1);
    // } else {
    return formatSeconds(timer, 0);
    // }
}

function formatSeconds(seconds, decimalPlaces = 0) {

    // show different times depending on "stuff"
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // If decimal places are required, calculate the fractional part
    if (decimalPlaces > 0) {
        // const fractionalSeconds = (remainingSeconds - Math.floor(remainingSeconds)).toFixed(decimalPlaces);
        // return `${formattedMinutes}:${formattedSeconds}${fractionalSeconds.substring(1)}`;
        const fractionalSeconds = Math.floor((seconds - Math.floor(seconds)) * (10 ** decimalPlaces));
        const formattedfractionalSeconds = String(fractionalSeconds).padStart(decimalPlaces, '0');


        // \?).toFixed(decimalPlaces);
        return `${formattedMinutes}:${formattedSeconds}.${formattedfractionalSeconds}`;
    } else {
        return `${formattedMinutes}:${formattedSeconds}`;
    }
}

export default EditTimeMain;
